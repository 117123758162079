import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  useCreateEntry,
  useGetIsBlackListed,
  useGetShop,
  useGetShopServices,
  useGetShopServiceSpecialities,
  useGetUnAvailableEntries,
} from '../hooks/shop';
import { Day } from '../types/shop';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';

type CreteaEntryForm = {
  name: string;
  phoneNumber: string;
  serviceId: string;
  specialityId: string;
  comment: string;
  date: Date;
  hour: string;
};

const dayIndexToName: Day[] = [
  Day.sunday,
  Day.monday,
  Day.tuesday,
  Day.wednesday,
  Day.thursday,
  Day.friday,
  Day.saturday,
];

export function getTimeStops(start?: string, end?: string, interval = 30) {
  if (!start || !end) {
    return [];
  }
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm').add(interval, 'minutes');

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  const timeStops = [];

  while (startTime < endTime) {
    timeStops.push(startTime.format('HH:mm'));
    startTime.add(interval, 'minutes');
  }
  return timeStops;
}

const schema = yup
  .object({
    name: yup.string().required('Please enter your name.'),
    phoneNumber: yup.string().required("Please enter your phone number. We'll send you a confirmation SMS."),
    serviceId: yup.string().required('Please select a speciality.'),
    specialityId: yup.string().required('Please select a service.'),
    comment: yup.string(),
    date: yup.date().required('Please select a date.'),
    hour: yup
      .string()
      .required(
        "You must select a time slot for your appointment. If you don't see any available time slots, please select another date or service.",
      ),
  })
  .required();

export default function CreateEntry() {
  const params = useParams();
  const id = params.id!;

  const currentDate = new Date(Date.now() + 1000 * 60 * 60 * 24);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    setError: setFormError,
    clearErrors,
    formState: { errors },
  } = useForm<CreteaEntryForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      phoneNumber: '',
      name: '',
      comment: '',
      hour: '',
      serviceId: '',
      specialityId: '',
      date: currentDate,
    },
  });

  const [error, setError] = React.useState<string>();

  const { mutate: createEntry, isLoading: isCreateEntryLoading } = useCreateEntry();
  const { data: shop, isLoading: isShopLoading } = useGetShop(id);
  const { data: services, isLoading: isServicesLoading } = useGetShopServices(id);
  const { data: specialities, isLoading: isSpecialitiesLoading } = useGetShopServiceSpecialities(
    id,
    watch('serviceId'),
  );
  const { data: unAvailableEntries } = useGetUnAvailableEntries(
    id,
    watch('serviceId'),
    watch('specialityId'),
    watch('date'),
  );

  console.log(services);

  const { data: isBlackListed } = useGetIsBlackListed(id, watch('phoneNumber').length > 6 ? watch('phoneNumber') : '');
  console.log(isBlackListed);

  const unAvailableHours = unAvailableEntries?.map((entry) => moment(entry.date.toDate()).format('HH:mm')) ?? [];
  console.log(unAvailableHours);

  // remove time slots that are reserved by entry lists duration
  const reservedTimeSlots = unAvailableEntries
    ?.map((entry) => {
      const start = moment(entry.date.toDate()).format('HH:mm');
      const end = moment(entry.date.toDate()).add(entry.duration, 'minutes').format('HH:mm');

      return getTimeStops(start, end, shop?.settings.breakdownBy).slice(1, -1);
    })
    .flat();

  const dayName = dayIndexToName[watch('date').getDay()];
  const hours = getTimeStops(
    shop?.settings.weeklyBreakdown[dayName]?.start,
    shop?.settings.weeklyBreakdown[dayName]?.end,
    shop?.settings.breakdownBy,
  ).filter((slot) => !reservedTimeSlots?.includes(slot));

  const isLoading = isShopLoading || isServicesLoading || isSpecialitiesLoading || isCreateEntryLoading;

  const onSubmit: SubmitHandler<CreteaEntryForm> = (data) => {
    if (!shop) return;

    const dateWithHour = moment(data.date).set({
      hour: +data.hour.split(':')[0],
      minute: +data.hour.split(':')[1],
    });

    if (dateWithHour.isBefore(moment())) {
      setError('Invalid date');
      return;
    }

    createEntry(
      {
        shopId: id,
        serviceId: data.serviceId,
        specialityId: data.specialityId,
        comment: data.comment,
        date: Timestamp.fromDate(dateWithHour.toDate()),
        client: {
          name: data.name,
          phoneNumber: data.phoneNumber,
        },
        duration: shop.settings.breakdownBy,
        price: specialities?.find((speciality) => speciality.id === data.specialityId)?.price ?? 0,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        deletedAt: null,
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
        onError: (err) => {
          setError(err.message);
        },
      },
    );
  };

  const handleUpdatePhoneNumber = (pn: string) => {
    const isValid = matchIsValidTel(pn);
    setValue('phoneNumber', pn);

    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      setFormError('phoneNumber', { type: 'validate', message: 'Please enter a valid phone number' });
    }
  };

  if (!shop && !isShopLoading)
    return (
      <Box
        sx={{
          height: {
            md: '100vh',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mx: 6,
        }}
      >
        <Typography variant="h6" color="text.primary">
          No Shop Found
        </Typography>
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          m: 2,
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
            p: 2,
            maxWidth: 400,
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Box mb={3}>
              <Typography variant="subtitle1" color="text.secondary">
                {shop?.name}
              </Typography>
              <Typography variant="h6" color="text.primary">
                Add Entry
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle1" color="text.primary">
                Name Surname
              </Typography>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                autoComplete="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register('name')}
              />
              <Box py={1} />

              <Typography variant="subtitle1" color="text.primary">
                Phone Number
              </Typography>
              <MuiTelInput
                required
                size="small"
                value={watch('phoneNumber')}
                onChange={handleUpdatePhoneNumber}
                hiddenLabel
                variant="outlined"
                margin="dense"
                autoComplete="phone"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                defaultCountry="KG"
              />
              <Box py={1} />

              <Typography variant="subtitle1" color="text.primary">
                Speciality
              </Typography>
              <FormControl error={!!errors.serviceId}>
                <Select variant="outlined" size="small" error={!!errors.serviceId} {...register('serviceId')}>
                  {services?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.serviceId?.message}</FormHelperText>
              </FormControl>
              <Box py={1} />

              <Typography variant="subtitle1" color="text.primary">
                Service
              </Typography>
              <FormControl error={!!errors.specialityId}>
                <Select
                  variant="outlined"
                  size="small"
                  error={!!errors.specialityId}
                  value={watch('specialityId')}
                  onChange={(e) => setValue('specialityId', e.target.value)}
                >
                  {specialities?.map((speciality) => (
                    <MenuItem key={speciality.id} value={speciality.id}>
                      {speciality.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.specialityId?.message}</FormHelperText>
              </FormControl>
              <Box py={1} />

              <Typography variant="subtitle1" color="text.primary">
                Comment
              </Typography>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                placeholder="Optional"
                multiline
                rows={8}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <CalendarPicker
              disabled={isLoading}
              date={moment(watch('date'))}
              minDate={moment(currentDate)}
              onChange={(date) => {
                if (date) {
                  setValue('date', date.toDate());
                  setValue('hour', '');
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              height: {
                md: '60vh',
              },
              display: 'flex',
              flexDirection: 'column',
              overflow: 'scroll',
            }}
          >
            {hours.map((hour, index) => (
              <Button
                disabled={isLoading || !watch('specialityId')}
                variant={watch('hour') === hour ? 'contained' : 'outlined'}
                sx={{ py: 2, my: 1 }}
                onClick={() => {
                  setValue('hour', hour);
                }}
                key={'hour_' + index}
              >
                {hour}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={watch('hour') !== ''}
        maxWidth="lg"
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
            px: 2,
            py: 1,
          },
        }}
      >
        <DialogTitle>Do you approve the event?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="caption" color="primary">
              {moment(watch('date')).format('DD MMMM YYYY')}
            </Typography>
            <Typography variant="h5" color="primary" sx={{ fontWeight: '600' }}>
              {watch('hour')}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ color: 'black' }}
                onClick={() => {
                  setValue('hour', '');
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" fullWidth onClick={handleSubmit(onSubmit)}>
                Approve
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={(isBlackListed ?? []).length > 0}
      >
        <Box
          height="100vh"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert severity="error">
            <AlertTitle>Blocked User</AlertTitle>
            User with <strong>{isBlackListed}</strong> is blocked
          </Alert>
        </Box>
      </Backdrop>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export const getDateString = (date?: Date | null) => {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
};

export const getTimeString = (start_time?: string | null) => {
  if (!start_time) return '';

  const end_time = (parseInt(start_time.split(':')[0]) + 1).toString().padStart(2, '0') + ':00';

  return `${start_time} - ${end_time}`;
};
